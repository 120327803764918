<template>
  <!--begin::Advance Table Widget 2-->
  <div v-if="isReady">
    <div class="card card-custom card-stretch gutter-b" v-for="row in total_row" :key="row">
      <!--begin::Header-->
      <div class="card-header border-0 pt-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">
            {{ $t('OrganizationChart.list', [row]) }}
          </span>
        </h3>

        <div class="card-toolbar">
          <ul class="nav nav-pills nav-pills-sm nav-dark-75">
            <li>
              <router-link :to="{name: 'organization_chart.add', params: {row: row}}" class="btn btn-sm btn-info">
                {{ $t('OrganizationChart.add') }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-3 pb-0">
        <!--begin::Table-->
        <div class="table-responsive">
          <table class="table table-borderless table-vertical-center" v-if="dataToShow[row]">
            <thead>
              <tr>
                <th class="p-0" style="width: 35%">
                  {{ $t('OrganizationChart.title') }}
                </th>
                <th class="p-0" style="width: 35%">
                  {{ $t('OrganizationChart.role') }}
                </th>
                <th class="p-0" style="min-width: 10%">
                  {{ $t('OrganizationChart.status') }}
                </th>
                <th class="p-0" style="min-width: 10%">
                  {{ $t('OrganizationChart.last_update') }}
                </th>
                <th class="p-0 text-right" style="min-width: 10%">
                  {{ $t('GENERAL.option') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, i) in dataToShow[row]">
                <tr v-bind:key="i">
                  <td class="pl-0">
                    <a class="text-dark-75">
                      {{ item.name | str_limit(128) }}
                    </a>
                  </td>
                  <td class="pl-0">
                    <a class="text-dark-75">
                      {{ item.role ? item.role : '-' | str_limit(128) }}
                    </a>
                  </td>
                  <td class="pl-0">
                    <div>
                      <b-badge variant="success" v-if="item.status == 1">Published</b-badge>
                      <b-badge variant="light" v-if="item.status == 0">Draft</b-badge>
                      <a class="text-muted font-weight-bold"> </a>
                    </div>
                  </td>
                  <td class="pl-0">
                    <div>
                      <a class="text-muted font-weight-bold">
                        {{ item.lu_datetime }}
                      </a>
                    </div>
                  </td>
                  <td class="text-right pr-0">
                    <router-link
                      :to="{
                        name: 'organization_chart.edit',
                        params: {id: item.id},
                      }"
                      class="btn btn-icon btn-light btn-sm mx-3"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon-->
                        <inline-svg src="media/svg/icons/Communication/Write.svg"></inline-svg>
                        <!--end::Svg Icon-->
                      </span>
                    </router-link>
                    <a class="btn btn-icon btn-light btn-sm" @click="deleteConfirmationModal(item.id)" ref="btnShow">
                      <span class="svg-icon svg-icon-md svg-icon-danger">
                        <!--begin::Svg Icon-->
                        <inline-svg src="media/svg/icons/General/Trash.svg"></inline-svg>
                        <!--end::Svg Icon-->
                      </span>
                    </a>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <div class="text-center" v-else>No Data</div>
        </div>
        <!--end::Table-->
      </div>
      <!--end::Body-->
      <!--end::Advance Table Widget 2-->
    </div>
  </div>

  <div class="card card-custom card-stretch gutter-b" v-else>
    <!--begin::Body-->
    <div class="card-body pt-3 p-20">
      <div class="d-flex justify-content-center mb-3">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import {LIST, QUERY, DELETE_POST} from '../services.js';
import {GET_OPTION} from '@/core/services/store/option.module';

export default {
  name: 'ListTable',
  data() {
    return {
      isReady: false,
      total_post: 0,
      total_row: 6,
      dataToShow: [],
      boxTwo: '',
    };
  },
  mounted() {
    //get row
    this.$store
      .dispatch(GET_OPTION, {key: 'organization_chart_row'})
      .then((res) => {
        // this.total_row = res.data;
        this.$store
          .dispatch(QUERY, {format: true})
          // go to which page after successfully login
          .then((res) => {
            // console.log(res);
            this.isReady = true;
            this.dataToShow = res.data;
            this.total_post = res.data.length;
          })
          .catch((error) => {
            // console.log(error);
          });
      })
      .catch((error) => {
        // console.log(error);
      });
  },
  components: {},
  methods: {
    deleteConfirmationModal(id) {
      this.boxTwo = '';
      this.$bvModal
        .msgBoxConfirm('Are you sure to delete this post?', {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Confirm',
          cancelTitle: 'Cancel',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.isReady = false;
            // console.log('Deleting post confirmation: ' + id);
            this.$store
              .dispatch(DELETE_POST, {id: id})
              // go to which page after successfully login
              .then((res) => {
                this.$store
                  .dispatch(QUERY, {format: true})
                  // go to which page after successfully login
                  .then((res) => {
                    this.isReady = true;
                    this.dataToShow = res.data;
                    this.total_post = res.data.length;
                  })
                  .catch((error) => {
                    // console.log(error);
                  });
              })
              .catch((error) => {
                // console.log(error);
              });
          }
        })
        .catch((err) => {});
    },
    deleteConfirm() {},
  },
  computed: {},
};
</script>
