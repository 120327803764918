<template>
  <div>
    <!--begin::Notice-->
    <!-- <b-alert
        show
        variant="light"
        class="alert alert-custom alert-white alert-shadow fade show gutter-b"
      >
        <div class="alert-icon">
          <span class="svg-icon svg-icon-lg">
            <inline-svg src="media/svg/icons/Tools/Compass.svg" />
          </span>
        </div>
        <div class="alert-text">
          <b>คำเตือน</b> ขณะนี้โปรแกรมอยู่ในช่วงปรัปปรุงระบบใหม่
          หากเจอเจอปัญหากรุณาแจ้งโปรแกรมเมอร์เพื่อแก้ไขโดยด่วน.
          
          <a
            class="font-weight-bold"
            href="https://bootstrap-vue.js.org/docs/components/alert"
            target="_blank"
          >
            See documentation.
          </a>
        </div>
      </b-alert> -->
    <!--end::Notice-->

    <div class="row">
      <div class="col-xxl-12">
        <ListTable />
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import ListTable from '../components/ListTable.vue';

export default {
  name: 'List',
  components: {
    ListTable,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: 'Organization Chart', route: 'organization_chart'}]);
  },
  methods: {},
};
</script>

<style></style>
